<template>
    <v-bottom-sheet v-model="dupeSheet" persistent> <v-card>
            <v-row no-gutters class="pt-3 pb-2" style="background-color: #F6F5F5;">
                <v-col col="6" class="text-left">
                    <v-btn text @click="cancelTimeSelect()" class="bold" plain depressed>CANCEL</v-btn>
                </v-col>
                <v-col col="6" class="text-right">
                    <v-btn text @click="selectTime()" class="bold" plain :disabled="disableSelectTime" depressed
                        color="#f2555c">
                        <p style="margin-bottom: 0;font-weight: bold;color: #f2555c;">SELECT </p>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col
                    :cols="daysSliderComputed && hoursSliderComputed && minutesSliderComputed ? '4' : (daysSliderComputed && !hoursSliderComputed && !minutesSliderComputed) ? '12' : '6'"
                    v-if="daysSliderComputed" class="text-center">
                    <p class="mb-0">Days</p>
                    <Picker style="height: 200px" :radius="days.length > 10 ? 200 : 60" :sensitivity="2"
                        :perspective="100" :visibleOptionsAmount="visibleOptions" :itemHeight="60" :options="days"
                        v-model="currentDays" />
                </v-col>
                <v-col
                    :cols="hoursSliderComputed && daysSliderComputed && minutesSliderComputed ? '4' : (hoursSliderComputed && !daysSliderComputed && !minutesSliderComputed) ? '12' : '6'"
                    v-if="hoursSliderComputed" class="text-center">
                    <p class="mb-0">Hours</p>
                    <Picker style="height: 200px" :radius="hours.length > 10 ? 200 : 60" :sensitivity="2"
                        :perspective="100" :visibleOptionsAmount="visibleOptions" :itemHeight="60" :options="hours"
                        v-model="currentHours" />
                </v-col>
                <v-col
                    :cols="minutesSliderComputed && daysSliderComputed && hoursSliderComputed ? '4' : (minutesSliderComputed && !daysSliderComputed && !hoursSliderComputed) ? '12' : '6'"
                    v-if="minutesSliderComputed" class="text-center">
                    <p class="mb-0">Mins</p>

                    <Picker style="height: 200px" :radius="60" :sensitivity="2" :perspective="100"
                        :visibleOptionsAmount="4" :itemHeight="60" :options="minutes" v-model="currentMinutes" />
                </v-col>
            </v-row>
        </v-card>
    </v-bottom-sheet>
</template>
<script>
import { EventBus } from "@/lib/EventBus";
import { mapGetters } from "vuex";
import { dateToTimeZoneDate, } from "@/utils/formatDateTime";

import { addMinutes, addDays, addHours, differenceInDays, differenceInHours, differenceInMinutes } from "date-fns";
import Picker from 'vue-wheel-picker';

export default {
    data: (vm) => ({
        visibleOptions: 4,
        days: [],
        hours: [],
        minutes: [],
        currentDays: { value: 0, text: '0 Days' },
        currentHours: { value: 0, text: '0 Hours' },
        currentMinutes: { value: 0, text: '0 Minutes' },
        dupeSheet: vm.sheet,
    }),
    mounted() {
        EventBus.$on('open-timepicker', () => {
                this.currentDays = { value: 0, text: '0 Days' },
                this.currentHours = { value: 0, text: '0 Hours' },
                this.currentMinutes = { value: 0, text: '0 Minutes' },
                this.setDefaultDate()
        })
    },
    props: {
        sheet: {
            type: Boolean,
            default: false
        },
        resEndTime: {
            type: String,
            default: () => {
                return ""
            }
        },
        timezone: {
            type: String,
            default: ""
        },
        maxParkingTime: {
            type: Object,
            default: () => {
                return {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    availableDays: [],
                    availableHours: [],
                    availableMinutes: [],
                }
            }
        },
    },
    components: {
        Picker
    },
    computed: {
        ...mapGetters({
            odDetails: "getterOdDetails",
            casinoDetails: 'getterCasinoDetails',
            templateFlags: 'getterTemplateFlags',
            mySpotDetails: 'getterMySpotDetails',
            zoneDetails: "getterZoneDetails",
        }),
        disableSelectTime() {
            return (this.currentDays.value == 0 && this.currentHours.value == 0 && this.currentMinutes.value == 0)
        },
        daysSliderComputed() {
            return this.days.length > 0
        },
        hoursSliderComputed() {
            return this.hours.length > 0
        },
        minutesSliderComputed() {
            return this.minutes.length > 0
        },
    },
    methods: {
        cancelTimeSelect() {
            this.days = [];
            this.hours = [];
            this.minutes = [];
            this.dupeSheet = false
        },
        selectTime() {
            EventBus.$emit('EXIT_TIME_SELECTED', { day: this.currentDays.value, hour: this.currentHours.value, minute: this.currentMinutes.value })
            this.dupeSheet = false
        },
        setDefaultDate() {
            this.hours = []
            this.days = []
            this.minutes = []
            let addeddays = addDays(this.resEndTime != "" ? new Date(this.resEndTime) : dateToTimeZoneDate(new Date(), this.zoneDetails.timezone), this.maxParkingTime?.days);
            let addedhours = addHours(addeddays, this.maxParkingTime?.hours);
            let addedmins = addMinutes(addedhours, this.maxParkingTime?.minutes);
            this.availableDays = differenceInDays(addedmins, (this.resEndTime != "" ? new Date(this.resEndTime) : dateToTimeZoneDate(new Date(), this.zoneDetails.timezone)))
            let hourDifference = differenceInHours(addedmins, (this.resEndTime != "" ? new Date(this.resEndTime) : dateToTimeZoneDate(new Date(), this.zoneDetails.timezone)))
            let minsDifference = differenceInMinutes(addedmins, (this.resEndTime != "" ? new Date(this.resEndTime) : dateToTimeZoneDate(new Date(), this.zoneDetails.timezone)))
            this.availableHours = 23
            if (hourDifference < 23) {
                this.availableHours = hourDifference
            }
            this.availableMins = 45
            if (minsDifference < 45) {
                this.availableMins = minsDifference
            }
            for (let index = 0; index <= this.availableDays; index++) {
                this.days.push({ value: index, text: index.toString() })
            }
            for (let index = 0; index <= this.availableHours; index++) {
                this.hours.push({ value: index, text: index.toString() })
            }
            for (let index = 0; index <= this.availableMins; index += 15) {
                this.minutes.push({ value: index, text: index.toString() })
            }
            this.dupeSheet = true;
        }
    }
}
</script>
<style>
.picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    height: 150 !important;
    background-color: #F8F8FA;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
    color: #bbbcc9;
}

.picker_chosen {
    background-color: #F8F8FA;
    border-top: 1px solid #dddde4;
    border-bottom: 1px solid #dddde4;
    color: #121212;
    font-size: 15px;
}

.picker_chosen_list {
    padding-left: 0px !important;

}
</style>