<template>
  <v-bottom-sheet v-model="showDurationPicker" max-width="800" ref="showDurationPicker" scrollable>
    <v-card height="300">
      <v-container fluid pa-0>
        <v-row no-gutters class="justify-space-between pa-4">
          <v-col cols="6" class="text-left">
            <v-btn text @click="cancel()">Cancel</v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn text class="red--text accent-2" :disabled="disableConfirmBtn" @click="confirm()">Confirm</v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="12" class="text-center grey lighten-2 py-2">
                  <span>Days</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="duration-list">
                <v-col cols="12">
                  <v-list>
                    <v-list-item-group v-model="selectedDay" color="red accent-2">
                      <v-list-item v-for="day in days" :key="'day-' + day">
                        <v-list-item-title class="text-center">{{ day }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>

          </v-col>
          <v-col cols="4">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="12" class="text-center grey lighten-2 py-2">
                  <span>Hours</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="duration-list">
                <v-col cols="12">
                  <v-list>
                    <v-list-item-group v-model="selectedHour" color="red accent-2">
                      <v-list-item v-for="hour in hours" :key="'hr-' + hour">
                        <v-list-item-title class="text-center">{{ hour }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>

          </v-col>
          <v-col cols="4">
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col cols="12" class="text-center grey lighten-2 py-2">
                  <span>Minutes</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="duration-list">
                <v-col cols="12">
                  <v-list>
                    <v-list-item-group v-model="selectedMinute" color="red accent-2">
                      <v-list-item v-for="minute in minutes" :key="'min-' + minute">
                        <v-list-item-title class="text-center">{{ minute }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-bottom-sheet>
</template>
    
<script>
import { EventBus } from "@/lib/EventBus";

export default {
  name: "SphereDurationPicker",
  props: {
    maxDurationTime: {
      type: Object,
      default: function () {
        return {
          days: 0,
          hours: 0,
          minutes: 0
        }
      }
    },
  },
  data() {
    return {
      showDurationPicker: false,
      selectedDay: null,
      selectedHour: null,
      selectedMinute: null,
      days: [],
      hours: [],
      minutes: []
    }
  },
  mounted() {
    EventBus.$on('open-duration-picker', () => {
      this.showDurationPicker = true;
      this.initDurationPicker();
    })
  },
  watch: {
    /**
     * set the hours to max parking hours if the day selected is maxParkingDays
     */
    selectedDay() {
      if (this.selectedDay == this.maxDurationTime?.days) {
        this.selectedHour = null;
        this.selectedMinute = null;
        this.hours = [...Array(this.maxDurationTime?.hours + 1).keys()];
      } else {
        this.initHours()
      }
    },
    /**
     * set the minutes to max parking minutes if the day selected is maxParkingDay and hour selected is maxParkingHours
     */
    selectedHour() {
      if (this.selectedDay == this.maxDurationTime?.days && this.selectedHour == this.maxDurationTime?.hours) {
        this.minutes = [...Array(this.maxDurationTime?.minutes + 1).keys()];
        this.selectedMinute = null;
      } else {
        this.initMinutes()
      }
    }
  },
  computed: {
    /**
     * disable the confirm button if the selected day/hour/min is 0 or null 
     */
    disableConfirmBtn() {
      let disabled = this.selectedDay == null || this.selectedHour == null || this.selectedMinute == null;
      disabled = disabled || (this.selectedDay == "0" && this.selectedHour == "0" && this.selectedMinute == "0");
      return disabled
    }
  },
  methods: {
    /** 
     * @method initDurationPicker - set the days to max parking days(props), hours to 0-24 and minutes to [0,15,30,45]
     */
    initDurationPicker() {
      this.resetDurationPicker();
      this.initDays();
      this.initHours();
      this.initMinutes();
    },
    /** 
    * @method resetDurationPicker - set the selectedDay/Hour/Min to null
    */
    resetDurationPicker() {
      this.selectedDay = null;
      this.selectedHour = null;
      this.selectedMinute = null;
    },
    /**
     * @method initDays - set the days to max parking days(props)
     */
    initDays() {
      this.days = [...Array(this.maxDurationTime?.days + 1).keys()];
    },
    /**
     * @method initHours - set the hours to 0-24 if max parking days != 0
     */
    initHours() {
      this.hours = this.maxDurationTime?.days != 0 ? [...Array(24).keys()] : [...Array(this.maxDurationTime?.hours + 1).keys()];
    },
    /**
    * @method initMinutes - set minutes to [0,15,30,45] if max parking days != 0 and max parking hours !=0
    */
    initMinutes() {
      this.minutes = (this.maxDurationTime?.days != 0 && this.maxDurationTime?.hours != 0) ? [...Array(60).keys()].filter((a) => a % 15 == 0) :
        [...Array(this.maxDurationTime?.minutes + 1).keys()];
    },
    confirm() {
      EventBus.$emit('on-confirm-duration-picker', {day:this.selectedDay, hour:this.selectedHour, minute:this.selectedMinute});
      this.showDurationPicker = false;
    },
    cancel(){
      this.showDurationPicker = false;
    }
  }
}
</script>
    
<style lang="css" >
.duration-list {
  height: 180px;
  overflow-y: auto !important;
}
.sphere-primary {
  color: #f2555c;
}
</style>