<template>
    <v-container>
        <v-row class="text-center" no-gutters>
            <v-col cols="12">
                <v-img alt="PARKING.COM" class="my-3" height="42" contain src="@/assets/logo_huge_drk.png" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-card class="mx-auto rounded-lg" max-width="374">
                    <v-dialog v-model="loading" persistent>
                        <v-card class="pa-4">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                            <span class="ml-4" style="font-size: 14px">Please wait...</span>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="odSessionLoading" persistent>
                        <v-card class="pa-4">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                            <span class="ml-4" style="font-size: 14px">Processing your payment.</span>
                        </v-card>
                    </v-dialog>
                    <template slot="progress">
                        <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
                    </template>
                    <v-card color="transparent" style="border-radius: 10px 10px 10px 10px;">
                        <v-img class="rounded-t-lg " height="auto" :src="bookingDetails.hasOwnProperty('zone') &&
              bookingDetails.zone.image != '' &&
              bookingDetails.zone.image != null
              ? bookingDetails.zone.image
              : zoneImgURL
              " :lazy-src="bookingDetails.hasOwnProperty('zone') &&
    bookingDetails.zone.image != '' &&
    bookingDetails.zone.image != null
    ? bookingDetails.zone.image
    : zoneImgURL
                                " alt="Parking.com garage">
                            <v-row no-gutters>
                                <v-col> <v-overlay absolute></v-overlay>
                                </v-col>
                                <v-col cols="12">
                                    <div class=" booking_header white--text text-lef mx-4" style="z-index: 100;">
                                        <span style="z-index: 10;">
                                            {{
                                                bookingDetails.hasOwnProperty("booking")
                                                    ? bookingDetails.booking.header
                                                    : ""
                                            }}
                                        </span>
                                    </div>
                                </v-col>
                                <!-- <v-col cols="12" v-if="isPreAuthEnabled == true">
                                    <pre-auth-sheet
                                        style="position: absolute;bottom:0;z-index: 100;padding: 5px;"></pre-auth-sheet>

                                </v-col> -->
                            </v-row>


                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>

                        </v-img>
                    </v-card>
                    <v-card-text v-if="exitDateTime > 0">
                        <v-container fluid pa-0>

                            <v-row class="mx-0 pb-0 black--text pl-2" no-gutters>
                                <v-row no-gutters class="pb-4"><v-col>
                                        <div>
                                            Checked in at
                                            {{
                                                bookingDetails.hasOwnProperty("booking") ? booking_content : ""
                                            }}

                                        </div>
                                        <span v-if="bookingDetails.hasOwnProperty('booking') &&
                                            bookingDetails.hasOwnProperty('zone') &&
                                            bookingDetails.booking.revEnd != null &&
                                            bookingDetails.booking.revEnd != ''
                                        ">

                                            {{ checkout_content }}
                                        </span>
                                    </v-col></v-row>
                            </v-row>
                            <v-container fluid pa-0 style="margin-left: 4.5rem;">
                                <v-row no-gutters>
                                    <v-col cols="12" class="pl-1    ">
                                        <v-row no-gutters class="counter-content-position">
                                            <v-col cols="12" class="pa-0">
                                                <v-icon class="material-icons-outlined pr-2"
                                                    style="font-size: 50px">history_toggle_off</v-icon>
                                                <v-skeleton-loader v-show="!bookingDetails.hasOwnProperty('booking')
                                                    " height="40px" max-width="200" type="heading"></v-skeleton-loader>
                                                <span class="grey--text text--darken-1 time_ago" v-show="exitDateTime > 0
                                                    ">{{ countDownExitTime }}</span>
                                                <p class="grey--text text--darken-1 time_ago_context pl-15 "
                                                    style="margin-top: -15px;" v-if="exitDateTime > 0">Remaining</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-text>
                    <!-- Checkout in progress -->
                    <!-- <v-card-text v-else-if="exitDateTime < 0">
                        <v-row class="text-center mt-2">
                            <v-col cols="12" class="black--text">
                                <p class="text-body-2 font-weight-bold">Checkout In Progress</p>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mx-0">
                            <v-col class="pa-1">
                                <p class="black--text">
                                    {{
                                        bookingDetails.hasOwnProperty("booking")
                                        ? bookingDetails.booking.header
                                        : ""
                                    }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="" class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                                    bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                                    bookingDetails.booking.TypeOfBooking == 'Monthly'
                                    ">
                                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Monthly</v-btn>
                            </v-col>
                            <v-col cols="" class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                                bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                                bookingDetails.booking.TypeOfBooking == 'Employee'
                                ">
                                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Employee</v-btn>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-1 mx-0"
                            v-if="bookingDetails.hasOwnProperty('booking') && bookingDetails.hasOwnProperty('zone') && bookingDetails.booking.vehicleNo != null && bookingDetails.booking.vehicleNo != ''">
                            <v-col cols="4" class="text-left pa-1">
                                <p class="booking_date_header mb-0">License Plate :</p>
                            </v-col>
                            <v-col class="text-right pa-1">
                                <p class="booking_date mb-0">
                                    {{ bookingDetails.hasOwnProperty("booking") ? bookingDetails.booking.vehicleNo : "" }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-1 mx-0">
                            <v-col cols="4" class="text-left pa-1">
                                <p class="booking_date_header mb-0">Booked At :</p>
                            </v-col>
                            <v-col class="text-right pa-1">
                                <p class="booking_date mb-0">
                                    {{
                                        bookingDetails.hasOwnProperty("booking") &&
                                        bookingDetails.booking.hasOwnProperty("entrytime")
                                        ? formatDateTime(bookingDetails.booking.entrytime)
                                        : ""
                                    }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mx-0">
                            <v-col cols="4" class="text-left pa-1">
                                <p class="booking_date_header">Expired At :</p>
                            </v-col>
                            <v-col class="text-right pa-1">
                                <p class="booking_date">
                                    {{
                                        bookingDetails.hasOwnProperty("booking") &&
                                        bookingDetails.booking.hasOwnProperty("exittime")
                                        ? formatDateTime(bookingDetails.booking.exittime)
                                        : ""
                                    }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-if="bookingDetails.hasOwnProperty('booking') &&
                                bookingDetails.booking.state != '11'
                                ">
                            <v-col cols="12" class=" text-center " style="font-size: 12px">
                                <v-row no-gutters class="pt-2 px-3">
                                    <v-col cols="4" class="text-left">
                                        <p style="font-size: small" class="black--text">
                                            Parking
                                        </p>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="4" class="text-left">
                                        <p style="font-size: small" class="black--text text-right">
                                            {{ bookingDetails.hasOwnProperty("booking") &&
                                                bookingDetails.booking.hasOwnProperty('estimatedPrice') ? "$ " +
                                            Number(bookingDetails.booking.estimatedPrice).toFixed(2) : "-" }}

                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="mt-0 px-3">
                                    <v-col cols="4" class="text-left">
                                        <v-row no-gutters>
                                            <v-col cols="11">
                                                <p style="font-size: small" class="black--text">
                                                    Service Fee
                                                </p>
                                            </v-col>
                                            <v-col cols="1">
                                                <v-tooltip bottom v-model="show">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="material-icons-outlined" dark x-small color="primary"
                                                            v-bind="attrs" @click="show = !show" v-on="on">info</v-icon>
                                                    </template>
                                                    <span>This fee helps cover the use of the technology that provides a
                                                        better parking
                                                        experience.</span>
                                                </v-tooltip>
                                            </v-col>

                                        </v-row>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="4" class="text-left">
                                        <p style="font-size: small" class="black--text text-right">
                                            {{ bookingDetails.hasOwnProperty("booking") &&
                                                bookingDetails.booking.hasOwnProperty('estimatedFee') ? "$ " +
                                            Number(bookingDetails.booking.estimatedFee).toFixed(2) : "-" }}

                                        </p>
                                    </v-col>
                                </v-row>
                                <hr class="total" />
                                <v-row no-gutters class="mt-2 px-3">
                                    <v-col cols="6" class="text-left">
                                        <p style="font-weight: bold;color:#F2555C;font-size:15px">
                                            Purchase Total <sup>*</sup>
                                        </p>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="4" class="text-left">
                                        <p style="font-size: small;font-weight: bold;color:#F2555C" class=" text-right">
                                            {{ bookingDetails.hasOwnProperty("booking") &&
                                                bookingDetails.booking.hasOwnProperty('estimatedTotal') ? "$ " +
                                            Number(bookingDetails.booking.estimatedTotal).toFixed(2) : "-" }}

                                        </p>
                                    </v-col>
                                    <p>
                                        *Includes state and local taxes of {{ bookingDetails.hasOwnProperty("booking") &&
                                            bookingDetails.booking.hasOwnProperty('estimatedTax') ? "$ " +
                                        Number(bookingDetails.booking.estimatedTax).toFixed(2) : "-" }}

                                    </p>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text> -->
                    <!-- Checked in options -->
                    <v-card-actions v-if="exitDateTime > 0" class="text-center pt-0 mt-0 pb-10">
                        <v-container class="pa-0" fluid>
                            <v-row no-gutters>
                                <v-col cols="12" class=" text-center " style="font-size: 12px">
                                    <v-card flat style="background-color: #F2555C14;border-radius: 10px">
                                        <v-row no-gutters class="pt-2 px-3">
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small" class="black--text">
                                                    Parking
                                                </p>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small" class="black--text text-right">
                                                    {{ this.checkODExtended ?
                                                        "$ " +
                                                        Number(bookingDetails.booking.OD_Extensions.overAllDetails.price).toFixed(2)
                                                        :

                                                        bookingDetails.hasOwnProperty("booking") &&
                                                            bookingDetails.booking.hasOwnProperty('estimatedPrice') ? "$ " +
                                                        Number(bookingDetails.booking.estimatedPrice).toFixed(2) : "-" }}

                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-0 px-3">
                                            <v-col cols="4" class="text-left">
                                                <v-row no-gutters>
                                                    <v-col cols="11">
                                                        <p style="font-size: small" class="black--text">
                                                            Service Fee
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="1">
                                                        <v-tooltip bottom v-model="show">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon class="material-icons-outlined" dark x-small
                                                                    color="primary" v-bind="attrs" @click="show = !show"
                                                                    v-on="on">info</v-icon>
                                                            </template>
                                                            <span>This fee helps cover the use of the technology that
                                                                provides a
                                                                better parking
                                                                experience.</span>
                                                        </v-tooltip>
                                                    </v-col>

                                                </v-row>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small" class="black--text text-right">
                                                    {{ this.checkODExtended ?
                                                        "$ " +
                                                        Number(bookingDetails.booking.OD_Extensions.overAllDetails.totalFee).toFixed(2)
                                                        :
                                                        bookingDetails.hasOwnProperty("booking") &&
                                                            bookingDetails.booking.hasOwnProperty('estimatedFee') ? "$ " +
                                                        Number(bookingDetails.booking.estimatedFee).toFixed(2) : "-" }}

                                                </p>
                                            </v-col>
                                        </v-row>
                                        <hr class="total" />
                                        <v-row no-gutters class="mt-2 px-3">
                                            <v-col cols="6" class="text-left">
                                                <p style="font-weight: bold;color:#F2555C;font-size:15px">
                                                    Amount Paid <sup>*</sup>
                                                </p>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" class="text-left">
                                                <p style="font-size: small;font-weight: bold;color:#F2555C"
                                                    class=" text-right">
                                                    {{ this.checkODExtended ?
                                                        "$ " +
                                                        Number(bookingDetails.booking.OD_Extensions.overAllDetails.price).toFixed(2)
                                                        :
                                                        bookingDetails.hasOwnProperty("booking") &&
                                                            bookingDetails.booking.hasOwnProperty('estimatedTotal') ? "$ " +
                                                        Number(bookingDetails.booking.estimatedTotal).toFixed(2) : "-" }}

                                                </p>
                                            </v-col>
                                            <p>
                                                *Includes state and local taxes of {{
                                                    this.checkODExtended ?
                                                        "$ " +
                                                        Number(bookingDetails.booking.OD_Extensions.overAllDetails.totalTax).toFixed(2)
                                                        :
                                                        bookingDetails.hasOwnProperty("booking")
                                                            &&
                                                            bookingDetails.booking.hasOwnProperty('estimatedTax') ? "$ " +
                                                        Number(bookingDetails.booking.estimatedTax).toFixed(2) : "-" }}

                                            </p>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="px-2 mt-13">
                                    <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                                        @click="openExtDialog()">Extend
                                        Parking</v-btn>
                                </v-col>
                            </v-row>
                            <v-dialog v-model="dateDialog" max-width="350px">
                                <v-date-picker color="#f2555c" v-model="selectedEndDate" :min="minDate" :max="maxDate"
                                    @change="setDateAndTime()">

                                </v-date-picker>
                            </v-dialog>
                            <v-dialog v-model="extensionDialog" fullscreen transition="scroll-y-transition" persistent>
                                <v-card>
                                    <v-card-title style="background-color: #EDF1F4;">
                                        <v-row no-gutters>
                                            <v-col cols="11" class="text-center pl-10">
                                                Extend Parking
                                            </v-col>
                                            <v-col>
                                                <v-icon class="material-icons-outlined text-right"
                                                    @click="extensionDialog = false">close</v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text class="pa-0 mb-14">
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-card
                                                    style="background-color:#F2555C14;border: 1px solid#F2555C80;border-radius: 10px !important;"
                                                    flat rounded class="mt-10 mb-4 mx-6">
                                                    <v-row class="text-center mt-3" no-gutters>
                                                        <v-col cols="2" class="pl-2 pb-4">
                                                            <v-icon class="material-icons-outlined"
                                                                style="background-color: white;border-radius: 5px;"
                                                                x-large>
                                                                history_toggle_off</v-icon>
                                                        </v-col>
                                                        <v-col cols="10" class="text-left">
                                                            <v-row no-gutters>
                                                                <v-col cols="12">
                                                                    Current parking expires at
                                                                </v-col>
                                                                <v-col cols="12" class="font-weight-bold">
                                                                    {{ formattedExitTime }}
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <!-- <p>Expires at {{ formattedExitTime }}</p> -->

                                                    </v-row>
                                                </v-card>
                                                <v-row no-gutters class="pt-2 mt-7 information pa-0">
                                                    <v-col cols="2" class="align-self-center">
                                                        <v-img
                                                            src="@/assets/list_divider_step.imageset/list_divider_step.png"
                                                            width="45px" v-if="!timeFlag">
                                                            <div class="row text-center align-self-center pl-5">
                                                                <div class="col-12 text-center">
                                                                    <span
                                                                        style="font-size:large;font-weight:bold;color:white">1</span>
                                                                </div>
                                                            </div>
                                                        </v-img>
                                                        <v-img
                                                            src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                                                            width="45px" v-if="timeFlag">
                                                            <div class="row text-center pl-5">
                                                                <div class="col-12 text-center">
                                                                    <span
                                                                        style="font-size:larger;font-weight:bold;color:white">✓</span>
                                                                </div>
                                                            </div>
                                                        </v-img>
                                                    </v-col>
                                                    <v-col cols="10" class="mt-3">
                                                        <p style="color:#F2555C;font-weight:bolder">Choose Duration of
                                                            Stay
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="pb-4">
                                                    <v-col cols="1" class="text-right align-self-center">
                                                        <v-icon class="material-icons-outlined">schedule</v-icon>
                                                    </v-col>
                                                    <!-- v-if="selectedOption == 'date'" -->

                                                    <v-col cols="11" class="px-3"
                                                        v-if="timePickerConfig && bookingDetails.zone.timePickerConfiguration == '0'">
                                                        <v-text-field @click="openDatePicker()" readonly
                                                            hide-details="auto" placeholder="Select a duration to exit"
                                                            v-model="formattedTime"></v-text-field>
                                                        <p class="black--text text-left pb-4"
                                                            style="padding-bottom: 0 !important;margin-bottom: 0 !important  ;">
                                                            {{ zoneEndTimeLabel }}</p>

                                                    </v-col>
                                                    <v-col cols="11" class="px-3"
                                                        v-if="timePickerConfig && bookingDetails.zone.timePickerConfiguration == '1'">
                                                        <v-text-field @click="openSphereTimePicker()" readonly
                                                            placeholder="Select a duration to exit"
                                                            v-model="formattedTime"></v-text-field>
                                                        <!-- v-if="timePickerConfig && bookingDetails.zone.timePickerConfiguration == '1'" -->
                                                    </v-col>
                                                </v-row>
                                                <SphereDateTimePicker :sheet="sheet" :resEndTime="resEndTime"
                                                    :maxParkingTime="OnDemandZoneDetails.maxParkingTime || bookingDetails.zone.maxParkingTime"
                                                    :timezone="zoneDetails.timezone">
                                                </SphereDateTimePicker>
                                                <sphere-duration-picker
                                                    :sphereDurationPickerSheet="sphereDurationPickerSheet"
                                                    :maxDurationTime="OnDemandZoneDetails.maxParkingTime || this.bookingDetails?.zone?.maxParkingTime"
                                                    @close="sphereDurationPickerSheet = false">
                                                </sphere-duration-picker>
                                                <v-row no-gutters class="pt-2 information pa-0"
                                                    v-if="estimate != null && Object.keys(estimate).length != 0">
                                                    <v-col cols="2" class="align-self-center">
                                                        <v-img
                                                            src="@/assets/list_divider_step.imageset/list_divider_step.png"
                                                            width="45px" v-if="!paymentFlag">
                                                            <div class="row text-center align-self-center pl-5">
                                                                <div class="col-12 text-center">
                                                                    <span
                                                                        style="font-size:large;font-weight:bold;color:white">3</span>
                                                                </div>
                                                            </div>
                                                        </v-img>
                                                        <v-img
                                                            src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                                                            width="45px" v-if="paymentFlag">
                                                            <div class="row text-center pl-5">
                                                                <div class="col-12 text-center">
                                                                    <span
                                                                        style="font-size:larger;font-weight:bold;color:white">✓</span>
                                                                </div>
                                                            </div>
                                                        </v-img>
                                                    </v-col>
                                                    <v-col cols="10" class="mt-3">
                                                        <p style="color:#F2555C;font-weight:bolder">Your Parking Fees
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                                <v-container fluid pa-0 class="px-4 pb-10"
                                                    v-if="estimate != null && Object.keys(estimate).length != 0"> <v-row
                                                        no-gutters class="pt-2 px-2">
                                                        <v-col cols="4" class="text-left">
                                                            <p style="font-size: small" class="black--text">
                                                                Parking
                                                            </p>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col cols="4" class="text-left">
                                                            <p style="font-size: small" class="black--text text-right">
                                                                {{ estimate.hasOwnProperty("quotedRegularPrice") ? "$ "
                                                                    +
                                                                    estimate.quotedRegularPrice.toFixed(2) : "-" }}

                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="pt-2 px-2">
                                                        <v-col cols="4" class="text-left">
                                                            <p
                                                                style="font-size: small;color: #f2555c;font-weight: bold;">
                                                                {{ estimate.alreadyPaid ? 'Already Paid' : 'Discount' }}
                                                            </p>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col cols="4" class="text-left">
                                                            <p style="font-size: small;color: #f2555c;font-weight: bold;"
                                                                class=" text-right">
                                                                {{
                                                                    estimate.hasOwnProperty('totalDiscount')
                                                                        ? "( $ " +
                                                                        Number(estimate.totalDiscount).toFixed(2) +
                                                                        " )"
                                                                        : "( - )"
                                                                }}
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mt-0 px-2">
                                                        <v-col cols="4" class="text-left">
                                                            <v-row no-gutters>
                                                                <v-col cols="11">
                                                                    <p style="font-size: small" class="black--text">
                                                                        Service Fee
                                                                    </p>
                                                                </v-col>
                                                                <v-col cols="1">
                                                                    <v-tooltip bottom v-model="show">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-icon class="material-icons-outlined" dark
                                                                                x-small color="primary" v-bind="attrs"
                                                                                @click="show = !show"
                                                                                v-on="on">info</v-icon>
                                                                        </template>
                                                                        <span>This fee helps cover the use of the
                                                                            technology
                                                                            that provides a better parking
                                                                            experience.</span>
                                                                    </v-tooltip>
                                                                </v-col>

                                                            </v-row>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col cols="4" class="text-left">
                                                            <p style="font-size: small" class="black--text text-right">
                                                                {{ estimate.hasOwnProperty("totalFee") ? "$ " +
                                                                    estimate.totalFee.toFixed(2) : "-" }}
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <hr class="total" />
                                                    <v-row no-gutters class="mt-2 px-2">
                                                        <v-col cols="6" class="text-left">
                                                            <p style="font-weight: bold;color:#F2555C;font-size:15px">
                                                                Total Amount <sup>*</sup>
                                                            </p>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col cols="4" class="text-left">
                                                            <p style="font-size: small;font-weight: bold;color:#F2555C"
                                                                class=" text-right">
                                                                {{ estimate.hasOwnProperty("grandTotal") ? "$ " +
                                                                    estimate.grandTotal.toFixed(2) : "-" }}

                                                            </p>
                                                        </v-col>
                                                        <p>
                                                            *Includes state and local taxes of {{
                                                                estimate.hasOwnProperty("totalTax") ? "$ " +
                                                                    estimate.totalTax.toFixed(2) : "-" }}
                                                        </p>
                                                    </v-row>
                                                    <v-row no-gutters class="px-4 pt-4 mb-10"
                                                        v-if="isAccessPromoCodeApplied==false && (bookingDetails.zone.dynamicExtension != '1' && bookingDetails.booking.validated != '1')">
                                                        <v-col cols="12">
                                                            <v-divider class="pt-8 "></v-divider>

                                                            <p style="margin-bottom: 0;font-weight: 500;">Promo/Access
                                                                Code
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-row no-gutters><v-col cols="8">
                                                                    <v-text-field outlined placeholder="code"
                                                                        v-model="accessPromoCode" class="rounded-lg"
                                                                        @keyup="uppercasePromoCode()">
                                                                    </v-text-field>
                                                                </v-col>
                                                                <v-col cols="4" class="text-right">
                                                                    <v-btn class="white--text rounded-lg "
                                                                        style="text-transform: none;"
                                                                        :disabled="!accessPromoCodeDisabled"
                                                                        color="#f2555c" :loading="accessBtnLoading"
                                                                        @click="applyAccessPromoCode()"
                                                                        x-large>Apply</v-btn>
                                                                </v-col></v-row>

                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="px-4 pt-4 pb-5 mb-10"
                                                        v-if="isAccessPromoCodeApplied && !(bookingDetails.zone.dynamicExtension == '1' && bookingDetails.booking.validated == '1')">
                                                        <v-col>
                                                            <v-divider class="pt-8 "></v-divider>
                                                            <v-card
                                                                style="background-color: #3EC6A51A;border: 0.75px solid #3EC6A5;">
                                                                <v-card-text>
                                                                    <v-row no-gutters>
                                                                        <v-col cols="1">
                                                                            <v-icon
                                                                                class="material-icons-outlined text-left"
                                                                                color="#3EC6A5">
                                                                                done
                                                                            </v-icon>
                                                                        </v-col>
                                                                        <v-col cols="8" class="text-left pl-4">
                                                                            <p
                                                                                style="margin-bottom: 0;font-weight: 500;font-size: 18px;">
                                                                                {{ accessPromoCode }}</p>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <v-btn color="#f2555c" text
                                                                                class="text-right"
                                                                                @click="removeAccessPromoCode()" small>
                                                                                REMOVE
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                                <v-footer fixed app class="pl-0 pr-0 pt-0" v-if="exitDateTime > 0">
                                    <v-row no-gutters>
                                        <!-- If there is existing CC and if user wants to change -->
                                        <v-col cols="12" style="display: flex;flex-direction: row;background: #0287D214;
                                            " v-if="userCardNumber != '' && checkOTPVerified">
                                            <v-row no-gutters>
                                                <v-col cols="6" class="text-left mt-2 pl-2">
                                                    <p>* {{ userCardNumber }} credit card on file</p>
                                                </v-col>
                                                <v-col cols="6" class="text-right  pr-2">
                                                    <v-btn @click="chargeODPayment()" :disabled="disableExtend"
                                                        color="#1a77d2" text
                                                        style="text-transform: none;;">Change</v-btn>
                                                    <v-icon
                                                        class="material-icons-outlined align-self-center">arrow_right</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" class="pl-2 pr-2">
                                            <p class=" text-center mt-2" style="font-size:13px">Parking fees will be
                                                upfront
                                            </p>
                                            <v-container fluid pa-0>
                                                <v-row no-gutters>
                                                    <!-- If there is existing CC and proceed with the transaction -->
                                                    <v-col :cols="paymentCols ? '12' : '6'" class="px-4"
                                                        v-if="userCardNumber != '' && checkOTPVerified">
                                                        <v-btn @click="createOdTransaction" large rounded
                                                            :loading="extendBtnLoading" elevation="20" color="#F2555C"
                                                            style="text-transform:none;border-radius: 60px !important"
                                                            class=" white--text " :disabled="disableExtend">

                                                            <v-row no-gutters>
                                                                <v-col cols="12" class="text-center">
                                                                    <p style="font-weight: bold;margin-bottom: 0;">Pay
                                                                        with CC</p>
                                                                </v-col>
                                                                <v-col cols="12" class="text-center">
                                                                    <p style="font-weight: 14px;margin-bottom: 0;"> (*
                                                                        {{ userCardNumber }})
                                                                    </p>
                                                                </v-col>
                                                            </v-row></v-btn>
                                                    </v-col>
                                                    <!-- If user wants to add new Card -->
                                                    <v-col :cols="paymentCols ? '12' : '6'" class="px-4"
                                                        v-if="userCardNumber == '' || !checkOTPVerified">
                                                        <v-btn rounded elevation="20" large class=" white--text "
                                                            color="#f2555c" :disabled="disableExtend"
                                                            @click="chargeODPayment()"
                                                            style="text-transform:none;border-radius: 60px !;">Add
                                                            Credit
                                                            Card</v-btn>
                                                    </v-col>
                                                    <v-col cols="6" class="px-4 pb-3" v-if="iosDevice &&
                                                        Object.hasOwnProperty.call(
                                                            bookingDetails.booking.paymentServices,
                                                            'apple_pay'
                                                        ) &&
                                                        bookingDetails.booking.paymentServices.apple_pay == '1'">
                                                        <apple-pay width='100%' height="43px"
                                                            :typeOfBooking="'OnDemandExtension'" :pendingState="false"
                                                            :amountRequired="estimate != null && estimate != undefined && estimate != '' && Object.hasOwnProperty.call(estimate, 'grandTotal') ? Number(estimate.grandTotal).toFixed(2) + '' : '1.00'"
                                                            style="border-radius: 50px;" :disabled="disableExtend"
                                                            :iosDevice="iosDevice &&
                                                                Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                                                                Object.hasOwnProperty.call(
                                                                    bookingDetails.booking,
                                                                    'paymentServices'
                                                                ) &&
                                                                Object.hasOwnProperty.call(
                                                                    bookingDetails.booking.paymentServices,
                                                                    'apple_pay'
                                                                ) &&
                                                                bookingDetails.booking.paymentServices.apple_pay == '1'"
                                                            :contact="bookingDetails.user.contact"
                                                            :extensionParams="extensionPar"
                                                            :licensePlate="bookingDetails.booking.vehicleNo"></apple-pay>
                                                    </v-col>
                                                    <v-col cols="6" class="px-2 pb-3" v-if="!iosDevice &&
                                                        Object.hasOwnProperty.call(
                                                            bookingDetails.booking.paymentServices,
                                                            'google_pay'
                                                        ) &&
                                                        bookingDetails.booking.paymentServices.google_pay == '1'">
                                                        <google-pay width='100%' height="43px" :disabled="disableExtend"
                                                            :amountRequired="estimate != null && estimate != undefined && estimate != '' && Object.hasOwnProperty.call(estimate, 'grandTotal') ? Number(estimate.grandTotal).toFixed(2) + '' : '0.00'"
                                                            :pendingState="false" :typeOfBooking="'OnDemandExtension'"
                                                            :iosDevice="iosDevice"
                                                            :licensePlate="bookingDetails.booking.vehicleNo"
                                                            :contact="bookingDetails.user.contact"
                                                            :extensionParams="extensionPar"></google-pay>
                                                    </v-col>
                                                </v-row>
                                            </v-container>


                                        </v-col>
                                    </v-row>
                                </v-footer>
                            </v-dialog>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="persistentDialog" persistent max-width="290">
            <v-card class="pa-0 pb-5">
                <v-card-title class="justify-center" style="position: relative">
                    <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
                </v-card-title>
                <v-card-text class="text-center mt-5">
                    <span style="font-size: 20px">{{ persistentMsg }}</span>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="alertDialog" persistent max-width="290">
            <v-card class="pa-0">
                <v-card-title class="justify-center" style="position: relative">
                    <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
                </v-card-title>
                <v-card-text class="text-center mt-5">
                    <span style="font-size: 20px">{{ alertMsg }}</span>
                </v-card-text>
                <v-card-actions class="text-center pb-6">
                    <v-container pa-0 fluid>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                                    @click="alertDialog = false">Close</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import SphereDurationPicker from '@/uiComponents/SphereDurationPicker.vue';
// import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";
import SphereDateTimePicker from '@/uiComponents/SphereDateTimePicker.vue'
import GooglePay from '@/views/GooglePay.vue'
import ApplePay from "@/views/ApplePay.vue";
import { differenceInMilliseconds, addDays, addHours, addMinutes } from 'date-fns';
import { EventBus } from "@/lib/EventBus";
import APIHelper from "../../apiHelper";
// import axios from "axios";
import { format, } from "date-fns";
import { dateToTimeZoneDate, format24TimeToAMPM } from "@/utils/formatDateTime";
import { secondsToHms } from "@/utils/formatDateTime";
import moment from 'moment'
import rules from "@/utils/rules";
Vue.use(moment);
export default {
    data: (vm) => ({
        persistentDialog: false,
        persistentMsg: '',
        sphereDurationPickerSheet: false,
        accessBtnLoading: false,
        isAccessPromoCodeValid: false,
        isAccessPromoCodeApplied: false,
        codeType: 'promoCode',
        accessPromoCode: "",
        selectedEndDate: "",
        selectedOption: "date",
        dateDialog: false,
        odSessionLoading: false,
        extensionPar: {
            IsExtension: true, mainODTxnRefno: vm.bookingDetails?.booking?.bid
        },
        showAddPayment: false,
        sheet: false,
        stopAt: vm.extensionTime,
        iosDevice: false,
        userCardNumber: "",
        zoneEndTimeLabel: "",
        show: false,
        alertDialog: false,
        minTimeDate: null,
        maxTimeDate: null,
        loading: false,
        alertMsg: "",
        extendBtnLoading: false,
        getRateCall: false,
        windowHeight: window.innerHeight - 350,
        estimate: null,
        oldEstimate: null,
        totalEstimate: null,
        stopTime: "",
        exitTimeSelected: null,
        timePickerHrRange: null,
        timePickerMinRange: null,
        minDate: null,
        maxDate: null,
        Datemenu: false,
        exitDate: null,
        dateFormatted: null,
        invalidSMSMsg: "",
        extensionTime: '',
        rules: rules,
        extensionDialog: false,
        exitDateTime: null,
    }),
    components: {
        SphereDateTimePicker, GooglePay, SphereDurationPicker,
        ApplePay,
        // PreAuthSheet
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            vm.$store.commit("SET_TEMPLATE_FLAGS", 'ondemand-flag');
            await vm.getBookingDetails()
            // vm.setDefaultDate()

        });
    },
    methods: {

        async squarePay(tokenResult, verfiedToken, paymentType) {
            try {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: true,
                }); let entryDateTime = this.bookingDetails?.booking?.OD_Extensions != null && this.bookingDetails?.booking?.OD_Extensions != undefined ?
                    format(new Date(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.exitTime), "yyyy-MM-dd HH:mm:ss") :
                    format(
                        new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date')),
                        "yyyy-MM-dd HH:mm:ss"
                    );
                var exitDateTime = format(this.extensionTime, "yyyy-MM-dd HH:mm:ss")

                let postData = {
                    userDetail: {
                        plate: this.bookingDetails.booking.vehicleNo,
                        contact: "+" + this.bookingDetails.user.contact,
                        exitTime: exitDateTime,
                        eventType: "OD",
                    },
                    cardDetail: {
                        cardType: this.bookingDetails?.booking?.cardType,
                        userDevice: "2",
                        orderType: "3",
                        entry: [
                            {
                                locationCode: this.bookingDetails?.zone?.zcode,
                                startAt: entryDateTime,
                                quantity: "1",
                            },
                        ],
                        paymentType: paymentType,
                        billingDetails: tokenResult?.details?.billing,
                        verification_token: verfiedToken,
                        source_id: tokenResult.token,
                        phoneNumber: '+' + this.bookingDetails?.user?.contact,
                        email: tokenResult?.details?.shipping?.contact?.email || "",
                    },
                    IsExtension: true,
                    mainODTxnRefno: this.bookingDetails?.booking?.bid,
                    mid: this.zoneDetails.mid,
                    source: "web",
                };
                if (this.codeType == "promoCode") {
                    Object.assign(postData, { promoCode: this.isAccessPromoCodeValid || null });
                }
                else if (this.codeType == 'accessCode') {
                    Object.assign(postData, { accessCode: this.isAccessPromoCodeValid || null });
                }
                var odAddCard = await APIHelper(
                    "POST",
                    "/api/v1/prepaid/addCard",
                    postData
                );
                if (odAddCard?.data?.status == true) {
                    this.$store.commit("SET_LOADING_STATUS", {
                        property: "APP",
                        pending: false,
                    });
                    //url segment is app mixin computed property 
                    let url =
                        window.location.origin + '/g/' + this.bookingDetails?.booking?.bid;
                    window.location.replace(url);
                } else if (odAddCard?.data?.status == false) {
                    this.$store.commit("SET_LOADING_STATUS", {
                        property: "APP",
                        pending: false,
                    });
                    this.alertDialog = true;
                    this.alertMsg = odAddCard?.data?.message;
                }
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });
                this.gPayBtnLoading = false;
            } catch (error) {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });
                this.gPayBtnLoading = false;
                console.log(error);
            }
        },
        /**
        * @method applyDuration - applies the duration selected from duration picker to the current time.
        * @param {*} duration - format {day:selectedDay, hour:selectedHour, minute:selectedMinute}
        */
        applyDuration(duration) {
            let endTime = this.checkODExtended ? new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime) : new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date'));
            let addedDays = addDays(endTime, duration.day);
            let addedhours = addHours(addedDays, duration.hour);
            let addedMinutes = addMinutes(addedhours, Number(duration.minute));
            this.extensionTime = addedMinutes;
        },
        openSphereDurationPicker() {
            EventBus.$emit('open-duration-picker')
        },
        uppercasePromoCode() {
            if (rules.alphaNumeric(this.accessPromoCode) === "Must be alphanumeric") {
                this.accessPromoCode = this.accessPromoCode.replace(/[^\w\s]/gi, '')
            }
            this.accessPromoCode = this.accessPromoCode.trim()
            this.accessPromoCode = this.accessPromoCode.toUpperCase();
        },
        async applyAccessPromoCode() {
            this.accessBtnLoading = true;
            await this.dateTimeChange('promoCode')
            this.accessBtnLoading = false
        },
        removeAccessPromoCode() {
            this.dateTimeChange()
            this.isAccessPromoCodeValid = false
            this.isAccessPromoCodeApplied = false;
            this.accessPromoCode = null;
        },
        setDateAndTime() {
            let exitDateAndTime = this.selectedEndDate + " " + this.bookingDetails?.zone?.customizedEndTime

            this.extensionTime = new Date(exitDateAndTime)
            this.dateTimeChange()

        },
        setDefaultDate() {
            this.extensionTime = this.checkODExtended ? new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime) : new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date'));
            this.minDate = format(this.extensionTime, 'yyyy-MM-dd');
            //calculate max parkign time in seconds
            let maxParkingTime =
                this.OnDemandZoneDetails != null
                    ? Number(this.OnDemandZoneDetails?.maxParkingTime?.days) *
                    24 *
                    60 *
                    60 +
                    Number(this.OnDemandZoneDetails?.maxParkingTime?.hours) * 60 * 60 +
                    Number(this.OnDemandZoneDetails?.maxParkingTime?.minutes) * 60
                    : Number(this.bookingDetails?.zone?.maxParkingTime?.days) *
                    24 *
                    60 *
                    60 +
                    Number(this.bookingDetails?.zone?.maxParkingTime?.hours) * 60 * 60 +
                    Number(this.bookingDetails?.zone?.maxParkingTime?.minutes) * 60;
            let currentDateTime = new Date().getTime();

            //get max date in seconds based on max parking time
            let maxZoneDate =
                maxParkingTime != null ? currentDateTime + maxParkingTime * 1000 : null;
            //get max date with time and set it to maxDateTime
            this.maxDate = this.zoneDetails.timezone
                ? format(
                    dateToTimeZoneDate(
                        new Date(maxZoneDate),
                        this.zoneDetails.timezone
                    ),
                    "yyyy-MM-dd"
                )
                : format(new Date(), "yyyy-MM-dd");
            // this.bookingDetails?.zone?.timePickerConfiguration=='0' ? this.dateTimeChange() :""

        },

        openDatePicker() {
            this.setDefaultDate('openDatePicker')
            this.dateDialog = true;

        },
        async createOdTransaction() {
            //  let phoneNumber = this.contactObj.formattedNumber.substring(1, this.contactObj.formattedNumber.length)
            this.odSessionLoading = true;
            let exitDateTime = this.extensionTime != null || this.extensionTime !== undefined || this.extensionTime != "" ?
                format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.extensionTime, 1) : this.extensionTime, 'yyyy-MM-dd')
                +
                " " +
                (this.zoneDetails?.timePickerConfiguration == '0' ? format24TimeToAMPM(this.zoneDetails?.customizedEndTime) : format(this.extensionTime, 'hh:mm a')) : ""
            let entryDateTime = this.bookingDetails?.booking?.OD_Extensions != null && this.bookingDetails?.booking?.OD_Extensions != undefined ?
                format(new Date(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.exitTime), "yyyy-MM-dd HH:mm:ss") :
                format(
                    new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date')),
                    "yyyy-MM-dd HH:mm:ss"
                );
            var findUser = await APIHelper("POST", "/api/v1/findUserWithCC", {
                zid: this.bookingDetails?.zone?.zid,
                contact: this.bookingDetails?.user?.contact,
            });
            let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
                vehicleNo: this.bookingDetails?.booking?.vehicleNo,
                contact: this.bookingDetails?.user?.contact,
                entryTime: entryDateTime,
                exitTime: exitDateTime,
                zcode: this.zoneDetails?.zcode,
            });
            if (details?.data?.status) {
                if (findUser != null && findUser != undefined && findUser != "" && findUser?.data?.status) {
                    try {
                        let postData = {
                            userDetail: {
                                plate: this.bookingDetails?.booking?.vehicleNo,
                                contact: this.bookingDetails?.user?.contact,
                                exitTime: exitDateTime,
                                eventType: "OD",
                                eventId: this.selectedEvent?.eventId || ''
                            },
                            cardDetail: {
                                paymentType: "CREDIT_CARD",
                                userDevice: "2",
                                orderType: "3",
                                entry: [
                                    {
                                        locationCode: this.zoneDetails?.zcode,
                                        startAt: entryDateTime,
                                        quantity: "1"
                                    }
                                ],
                                payment: {
                                    email: findUser?.data?.userSavedCardDetail?.email
                                },
                            },
                            IsExtension: true,
                            mainODTxnRefno: this.bookingDetails?.booking?.bid,
                            userSavedCardDetail: {
                                customerId: findUser?.data?.userSavedCardDetail?.customerId,
                                accountId: findUser?.data?.userSavedCardDetail?.accountId,
                                paymentCardId: findUser?.data?.userSavedCardDetail?.paymentCardId,
                                email: findUser?.data?.userSavedCardDetail?.email,
                                paymentCardType: findUser?.data?.userSavedCardDetail?.paymentCardType,
                                paymentCardNo: findUser?.data?.userSavedCardDetail?.paymentCardNo,
                                saveCardUserType: findUser?.data?.userSavedCardDetail?.saveCardUserType
                            },
                            mid: this.zoneDetails.mid,
                            source: "web"
                        };
                        if (this.codeType == "promoCode") {
                            Object.assign(postData, { promoCode: this.isAccessPromoCodeValid || null });
                        }
                        else if (this.codeType == 'accessCode') {
                            Object.assign(postData, { accessCode: this.isAccessPromoCodeValid || null });
                        }
                        // if (
                        //     Object.hasOwnProperty.call(this.zoneDetails, 'paymentServices') && Object.hasOwnProperty.call(this.zoneDetails.paymentServices, 'credit_card') && this.zoneDetails.paymentServices.credit_card == '1') {
                        //     Object.assign(postData.cardDetail, { paymentType: "CREDIT_CARD" })
                        // }
                        var odAddCard = await APIHelper(
                            "POST",
                            "/api/v1/prepaid/addCard",
                            postData
                        );
                        if (odAddCard?.data?.status == true) {
                            //url segment is app mixin computed property
                            let url = window.location.origin + '/g/' + this.bookingDetails?.booking?.bid;
                            window.location.replace(url);
                        } else if (odAddCard?.data?.status == false) {
                            this.alertDialog = true;
                            this.alertMsg = odAddCard?.data?.message;
                        }
                        this.odSessionLoading = false;
                    } catch (error) {
                        this.odSessionLoading = false;
                        console.log(error);
                    }
                }
                else {
                    this.odSessionLoading = false;
                    this.alertMsg = findUser?.data?.message ? findUser?.data?.message : "";
                    this.alertDialog = true;
                }
            }
            else {
                this.odSessionLoading = false;
                this.alertMsg = details?.data?.message ? details?.data?.message : "";
                this.alertDialog = true;
                if (details?.data?.message == 'Booking Already Exists') {
                    //url segment is app mixin computed property
                    let url = window.location.origin + '/g/' + details?.data?.data?.refno
                    window.location.replace(url);

                }
            }
        },
        async findUser() {
            try {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: true,
                })
                var findUser = await APIHelper("POST", "/api/v1/findUserWithCC", {
                    zid: this.bookingDetails?.zone?.zid,
                    contact: this.bookingDetails?.user?.contact
                });
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                })
                if (findUser != null && findUser != undefined && findUser != "" && findUser?.data?.status) {
                    this.$store.commit("SET_LOADING_STATUS", {
                        property: "APP",
                        pending: false,
                    })
                    this.userCardNumber = findUser?.data?.userSavedCardDetail?.paymentCardNo
                }
                else {
                    this.$store.commit("SET_LOADING_STATUS", {
                        property: "APP",
                        pending: false,
                    })
                    this.userCardNumber = ''
                }
            } catch (e) {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                })
                console.log(e)
            }
        },
        chargeODPayment() {
            let exitDateTime = this.extensionTime != null || this.extensionTime !== undefined || this.extensionTime != "" ?
                format(this.extensionTime, 'yyyy-MM-dd')
                +
                " " +
                format(this.extensionTime, 'HH:mm:ss') : "";
            let paramValues = { exitDateTime: exitDateTime, }
            if (this.codeType == "promoCode") {
                Object.assign(paramValues, { promoCode: this.isAccessPromoCodeValid || null });
            }
            else if (this.codeType == 'accessCode') {
                Object.assign(paramValues, { accessCode: this.isAccessPromoCodeValid || null });
            }
            this.$router.replace({
                name: "odMakePayment",
                params: paramValues,
            });

        },
        async addPayment() {
            this.$router.replace({ path: "/addpayment" });
        },
        round(time) {
            let exitdate = time !== null && time !== '' ? format(time, 'yyyy/MM/dd') : new Date();
            let selectedMins = time !== null && time !== '' ? format(time, 'mm') : new Date();
            let selectedHours = time !== null && time !== '' ? format(time, 'hh') : new Date();
            let selectedAMPM = time !== null && time !== '' ? format(time, 'a') : new Date();
            if (exitdate != null && selectedMins != '15' && selectedMins != '30' && selectedMins != '45' && selectedMins != '00' && selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "") {
                let intervalMilliseconds = 900000
                let datetime = new Date(exitdate + " " + selectedHours + ":" + selectedMins + " " + selectedAMPM)
                let interval = datetime.getTime() % intervalMilliseconds;
                let delta = interval === 0 ? 0 : datetime.getTime() - interval;
                delta += intervalMilliseconds;
                // this.exitTimeSelected = Object.assign(this.exitTimeSelected, { hh: format(new Date(delta), 'hh'), mm: format(new Date(delta), 'mm'), a: format(new Date(delta), 'a'), })
                return new Date(delta)
            }
        },
        async getBookingDetails() {
            try {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: true,
                })
                let bookingId = window.location.pathname.substring(3)
                var bookingDetails = await APIHelper(
                    "GET",
                    "/api/v1/booking/config/" + bookingId
                );
                var zoneDetails = await APIHelper(
                    "GET",
                    "/api/v1/getOnDemand/" + this.bookingDetails?.zone?.locationCode,
                );
                this.findUser()
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                })
                if (zoneDetails?.data?.lot == undefined || zoneDetails?.data?.lot == null || zoneDetails?.data?.lot == '') {
                    this.persistentMsg = zoneDetails?.data?.message || ""
                    this.persistentDialog = true;
                }
                this.$store.commit("SET_ON_DEMAND_ZONE_DETAILS", zoneDetails.data?.lot);
                // bookingDetails.data.serverName == 'dev' ? document.querySelector('meta[name="theme-color"]').setAttribute("content", "#43A047") : "";
                this.$store.commit(
                    "SET_BOOKING_DETAILS",
                    bookingDetails.data?.data ? bookingDetails.data.data : null
                );
            } catch (error) {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                })
                console.log(error);
            }
        },
        async dateTimeChange(codeType) {
            try {
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: true,
                });
                if (Object.hasOwnProperty.call(this.bookingDetails, 'booking'))
                    //format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");      console.log()
                    this.extendBtnLoading = true;
                this.startDate = this.checkODExtended && this.bookingDetails?.booking?.OD_Extensions != null && this.bookingDetails?.booking?.OD_Extensions != undefined ?
                    format(new Date(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.exitTime), "yyyy-MM-dd'T'HH:mm:ss") :
                    format(
                        new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date')),
                        "yyyy-MM-dd'T'HH:mm:ss"
                    );
                this.startDate = this.bookingDetails?.zone?.dynamicExtension == '1' ? format(
                    new Date(this.formatExitDateTime(this.bookingDetails?.booking?.entrytime, 'date')),
                    "yyyy-MM-dd'T'HH:mm:ss"
                ) : this.startDate

                let stopTime = Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == '0' ? (this.zoneDetails?.customizedEndTime ? this.zoneDetails?.customizedEndTime : '23:59:00') : format(this.extensionTime, 'HH:mm:ss'); //?.hh+":"+this.exitTimeSelected.mm+":00"//format(this.checkoutDate, "yyyy-MM-dd'T'HH:mm:ss")
                let exitdate = this.extensionTime !== null && this.extensionTime !== undefined && this.extensionTime !== "" ? format(this.zoneDetails.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.extensionTime, 1) : this.extensionTime, 'yyyy-MM-dd') : "";
                let stopDate = exitdate + "T" + stopTime;
                let data = {
                    // orderType: '2',
                    startAt: this.startDate,
                    stopAt: stopDate,
                    zcode: this.bookingDetails?.zone?.locationCode,
                    bid: this.bookingDetails.booking.bid,
                };
                if (codeType == "promoCode") {
                    Object.assign(data, { promoCode: this.accessPromoCode || null });
                }
                else if (codeType == 'accessCode') {
                    Object.assign(data, { accessCode: this.accessPromoCode || null });
                }
                var getRate = await APIHelper(
                    "POST",
                    "/api/v2/getRate", data
                );
                this.dateDialog = false

                if (!getRate?.data?.status) {
                    if (codeType == 'promoCode' && !getRate?.data?.status) {
                        this.dateTimeChange('accessCode')
                    }
                    else {
                        this.extendBtnLoading = true;
                        this.alertDialog = true;
                        this.dateDialog = false;
                        this.alertMsg = getRate?.data?.message || "Error in getting rate details";
                        this.extendBtnLoading = false;
                        this.addPaymentBtnLoading = false;

                    }

                }
                else {
                    if (codeType == 'promoCode' && getRate?.data?.status) {
                        this.isAccessPromoCodeApplied = true;
                        this.isAccessPromoCodeValid = this.accessPromoCode
                        this.extendBtnLoading = false;
                        this.codeType = codeType;
                    }
                    else if (codeType == 'accessCode' && getRate?.data?.status) {
                        this.isAccessPromoCodeValid = this.accessPromoCode
                        this.isAccessPromoCodeApplied = true;
                        this.extendBtnLoading = false;
                        this.codeType = codeType;
                    }
                    this.zoneEndTimeLabel = this.bookingDetails?.zone?.timePickerConfiguration == '0' ? `You are eligible to stay till ${exitdate}` : ""
                    this.estimate = Object.assign({}, getRate?.data?.data);
                    EventBus.$emit('SQUARE_ESTIMATE_CHANGE')
                    this.selectedEvent = ""
                    this.addPaymentBtnLoading = false;
                    this.selectedOption = 'date'
                    this.extendBtnLoading = false;
                    this.dateDialog = false;
                    if (this.bookingDetails?.zone?.dynamicExtension == '1') {
                        var dynamicGrandTotal = Number(this.estimate?.grandTotal) - (this.checkODExtended ? Number(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.price) : Number(this.bookingDetails?.booking?.price))
                        var dynamicTotalPrice = Number(this.estimate?.totalPrice) - (this.checkODExtended ? Number(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.totalPrice) : Number(this.bookingDetails?.booking?.totalPrice))
                        var dynamicTotalDiscount = this.checkODExtended ? Number(this.bookingDetails?.booking?.OD_Extensions?.overAllDetails?.price) : Number(this.bookingDetails?.booking?.price);
                        Object.assign(this.estimate, {
                            grandTotal: dynamicGrandTotal > 0 ? dynamicGrandTotal : 0.00,
                            totalPrice: dynamicTotalPrice > 0 ? dynamicTotalPrice : 0.00,
                            totalDiscount: dynamicTotalDiscount > 0 ? dynamicTotalDiscount : 0.00,
                            alreadyPaid: true,
                        })
                    }

                    EventBus.$emit('datetime-set');
                }
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });
            } catch (error) {
                this.dateDialog = false
                this.extendBtnLoading = false;
                this.extendBtnLoading = false;
                console.log(error);
                this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });
            }
        },
        async openExtDialog() {
            this.loading = true
            var zoneDetails = await APIHelper(
                "GET",
                "/api/v1/getOnDemand/" + this.bookingDetails?.zone?.locationCode,
            );
            await this.getZoneDetails("?zcode", this.bookingDetails?.zone?.zcode);
            this.loading = false
            this.extensionDialog = true;
            // await axios.get(
            //   process.env.VUE_APP_ON_DEMAND_URL + "/" + "32899"+ ".json"
            // );
            this.$store.commit("SET_ON_DEMAND_ZONE_DETAILS", zoneDetails.data?.lot);
            // this.setTimePicker();
            this.extendBtnLoading = false;
        },

        openSphereTimePicker() {
            //the exit date value for the extended time
            this.exitDate = this.checkODExtended ? format(new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime), 'yyyy-MM-dd') : this.formatUTCDefaultDate(this.bookingDetails?.booking?.exittime
                , 'exit');
            //formatted exit date value to display
            this.dateFormatted =
                this.checkODExtended ? format(new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime), "MM/dd/yyyy") : this.formatUTCDefaultDate(this.bookingDetails?.booking?.exittime
                    , 'exit');
            this.extensionTime = this.checkODExtended ? new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime) : new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date'));
            EventBus.$emit('open-timepicker')
            this.sheet = true
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        /**
    * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
    */
        async getZoneDetails(searchParamKey, searchParamValue) {
            try {
                var zoneDetails = await APIHelper(
                    "GET",
                    "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
                );
                this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
            } catch (error) {
                console.log(error);
            }
        },
        calculateExitDateTime() {
            if (this.bookingDetails?.zone?.timezone &&
                this.bookingDetails?.booking?.entryMode == "OD") {

                var currentTime = dateToTimeZoneDate(new Date(), this.bookingDetails.zone.timezone);
                var exitTime = this.checkODExtended ? Number(new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime)) : new Date(this.formatExitDateTime(Number(this.bookingDetails.booking.exittime), 'date'));
                this.$store.commit("SET_EXIT_TIME_TIME_STATUS", this.exitDateTime < 0);
                differenceInMilliseconds(exitTime, currentTime) < 0 ? clearInterval(this.exitInterval) : "";
                return differenceInMilliseconds(exitTime, currentTime);
            }
            return 0

        },

        /** @methods to find the the UTC date 
        @params the bookings date value , type 'exit (2023-12-02)' or 'formatdate (12/02/2023)'
        */
        formatUTCDefaultDate(defaultDate, type) {
            let utcDate = new Date(defaultDate * 1000).toUTCString();
            let month = utcDate.split(",")[1].split(" ")[2]; // Jan
            let date = utcDate.split(",")[1].split(" ")[1]; // 27
            let year = utcDate.split(",")[1].split(" ")[3]; //2023
            let exitformat = format(new Date(month + " " + date + " " + year), 'yyyy-MM-dd');
            let dateformat = format(new Date(month + " " + date + " " + year), "MM/dd/yyyy");
            return type === "exit" ? exitformat : dateformat;

        },
        formatExitDateTime(time, val) {
            if (time !== null && time !== undefined && time != '') {
                let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
                let month = utcDate.split(",")[1].split(" ")[2]; // Jan
                let date = utcDate.split(",")[1].split(" ")[1]; // 27
                let year = utcDate.split(",")[1].split(" ")[3];
                let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
                let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
                let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
                formatHr = Number(hr) == 0 ? 12 : formatHr;

                formatHr = Number(formatHr) >= 10 ? Number(formatHr) : '0' + formatHr; //01
                let amOrpm = Number(hr) >= 12 ? "p" : "a"; //PM
                let ampm = Number(hr) >= 12 ? "PM" : "AM";
                switch (val) {
                    case 'hour': {
                        return formatHr + amOrpm;
                    }
                    case 'mins': {
                        return min
                    }
                    case 'date': {
                        return month + " " + date + ", " + year + " " + formatHr + ":" + min + " " + ampm;
                    }
                    case 'expire': {
                        return formatHr + ":" + min + " " + ampm + ' on ' + month + " " + date
                    }
                }
            }
            //Jan 27, 01:59 PM
        },
        formatDateTime(time) {
            let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
            let month = utcDate.split(",")[1].split(" ")[2]; // Jan
            let date = utcDate.split(",")[1].split(" ")[1]; // 27
            let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
            let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
            let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
            formatHr = Number(formatHr) >= 10 ? Number(formatHr) : "0" + formatHr; //01
            let amOrpm = Number(hr) >= 12 ? "PM" : "AM"; //PM
            return month + " " + date + ", " + formatHr + ":" + min + " " + amOrpm; //Jan 27, 01:59 PM
        },
    },
    watch: {
        // time(newVal, oldVal) {
        //     if (oldVal != null && newVal != null && oldVal != newVal && oldVal != "" && newVal != "" && (isValid(newVal))) {
        //         this.round(newVal);
        //     }
        // },
    },

    computed: {
        checkOTPVerified() {
            let odDetails = localStorage.getItem('odDetails');
            odDetails = odDetails ? JSON.parse(odDetails) : {};
            return odDetails?.otpVerified
        },
        paymentCols() {
            return (this.iosDevice && this.bookingDetails?.booking?.paymentServices?.apple_pay == '0') || (!this.iosDevice && this.bookingDetails?.booking?.paymentServices?.google_pay == '0')
        },
        accessPromoCodeDisabled() {
            return this.accessPromoCode != null && this.accessPromoCode != '' && this.accessPromoCode != undefined && this.extensionTime != null && this.extensionTime != undefined && this.extensionTime != ""
        },
        customEndTimeMinDate() {
            let currentTime = this.zoneDetails.timezone
                ?
                dateToTimeZoneDate(new Date(),
                    this.zoneDetails.timezone
                )
                : new Date();
            let givenTime = this.extensionTime
            let timeVal = this.zoneDetails.customizedEndTime != null && this.zoneDetails.customizedEndTime != undefined ? this.zoneDetails.customizedEndTime.split(':') : ['23', '59', '59']
            givenTime.setHours(timeVal[0], timeVal[1], timeVal[2])
            let check = false;
            if (givenTime <= currentTime) {
                check = true;
                return check
            }
            else {
                check = false
                return check
            }
        },
        timePickerConfig() {
            return Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails.zone, 'timePickerConfiguration') && this.bookingDetails?.zone?.timePickerConfiguration !== null && this.bookingDetails?.zone?.timePickerConfiguration != undefined
        },
        resEndTime() {
            return this.extensionTime != null && this.extensionTime != undefined && this.extensionTime != "" ? format(this.extensionTime, 'yyyy-MM-dd HH:mm') : ""
        },
        booking_content() {
            return this.bookingDetails?.booking?.entrytime ? this.formatDateTime(this.bookingDetails?.booking?.entrytime).split(",")[1] + " on " + this.formatDateTime(this.bookingDetails?.booking?.entrytime).split(",")[0] : ""
        },
        paymentFlag() {
            return this.estimate === null || this.estimate === '' || this.estimate.hasOwnProperty.call('grandTotal') || this.estimate.hasOwnProperty.call('quotedRegularPrice') || this.estimate.hasOwnProperty.call('totalFee') || this.estimate.hasOwnProperty.call('totalTax') ? false : true
        },
        timeFlag() {
            return this.extensionTime === null || this.estimate === null || this.estimate === '' ||
                this.extensionTime === "" || this.extensionTime === undefined ? false : true;
        },
        checkODExtended() {
            return this.bookingDetails?.booking?.OD_Extensions != null && this.bookingDetails?.booking?.OD_Extensions != undefined
        },
        formattedDateTime() {
            return this.extensionTime !== null && this.extensionTime !== "" ? format(this.extensionTime, 'iii, LLL dd') : ""
        },
        formattedYear() {
            return this.extensionTime !== null && this.extensionTime !== "" ? format(this.extensionTime, 'yyyy') : ""
        },
        ...mapGetters({
            bookingId: "getterBookingId",
            zoneDetails: "getterZoneDetails",
            bookingDetails: "getterBookingDetails",
            OnDemandZoneDetails: "getterOnDemandZoneDetails",
        }),
        formattedTime() {
            return this.extensionTime !== null && this.extensionTime !== undefined && this.extensionTime !== "" ? (this.zoneDetails?.timePickerConfiguration == '0' ? format(this.extensionTime, 'yyyy-MM-dd') : format(this.extensionTime, 'yyyy-MM-dd hh:mm aa')) : ""
        },
        formattedExitTime() {
            return this.bookingDetails?.booking?.OD_Extensions != null && this.bookingDetails?.booking?.OD_Extensions != undefined ? format(new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime), 'hh:mm a') + " ,  " + format(new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime), 'LLL dd') :
                this.bookingDetails?.booking?.exittime ? this.formatDateTime(this.bookingDetails?.booking?.exittime).split(",")[1] + " , " + this.formatDateTime(this.bookingDetails?.booking?.exittime).split(",")[0] : ""
        },
        checkout_content() {
            return this.bookingDetails?.booking?.OD_Extensions != null && this.bookingDetails?.booking?.OD_Extensions != undefined ? "Checkout before " + format(new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime), 'hh:mm a') + " on  " + format(new Date(this.bookingDetails?.booking?.OD_Extensions.overAllDetails.exitTime), 'LLL dd') : "Checkout before " + this.formatExitDateTime(this.bookingDetails.booking.exittime, 'expire')
        },
        isPreAuthEnabled() {
            return Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails?.zone, 'preAuth') && Object.hasOwnProperty.call(this.bookingDetails?.zone?.preAuth, 'enabled') && this.bookingDetails?.zone?.preAuth?.enabled == '1'
        },
        computedDateFormatted() {
            return this.extensionTime !== null && this.extensionTime !== "" && this.extensionTime !== undefined ? format(this.extensionTime, 'yyyy/MM/dd') : ""
        },
        disableExtend() {
            let disabled = (
                this.extensionTime === null || this.extensionTime === "" || this.extensionTime === undefined || this.estimate === null || this.estimate === undefined || Object.keys(this.estimate).length === 0 || this.disableAPGP)
            return disabled;
        },
        countDownExitTime() {
            if (this.exitDateTime < 0) {
                this.$store.commit("SET_VALIDATED_TIME_STATUS", true);
                clearInterval(this.exitTimeInterval);
            }
            return secondsToHms(this.exitDateTime / 1000 + 59);
        },
    },
    destroyed() {
        EventBus.$off('SQUARE_PAY_ODEXTENSION');
    },
    async mounted() {
        var self = this;
        this.$store.commit("SET_TEMPLATE_FLAGS", 'ondemand-flag');
        this.getMobileOperatingSystem() === 'iOS' ? this.iosDevice = true : this.iosDevice = false;
        this.exitDateTime = this.calculateExitDateTime();
        this.exitTimeInterval = setInterval(() => {
            if (
                this.bookingDetails?.zone?.timezone &&
                this.bookingDetails?.booking?.entryMode == "OD"
            ) {
                this.exitDateTime = this.calculateExitDateTime();
            }
        }, 1000);
        this.findUser()
        EventBus.$on('EXIT_TIME_SELECTED', (duration) => {
            this.applyDuration(duration);
            this.isAccessPromoCodeApplied ? this.dateTimeChange('promoCode') : this.dateTimeChange();
            this.sheet = false
        })
        EventBus.$on("SQUARE_PAY_ODEXTENSION", async (tokenResult, verfiedToken, paymentType) => {
            await self.squarePay(tokenResult, verfiedToken, paymentType)
        })
        // EventBus.$on('EXIT_TIME_SELECTED', (endTime) => {
        //     this.extensionTime = endTime;
        //     this.isAccessPromoCodeApplied ? this.dateTimeChange('promoCode') : this.dateTimeChange();
        //     this.findUser()
        //     this.sheet = false
        // })
    },
}
</script>

<style lang="less">
.information {
    background-color: #edf1f4;
}

.booking_header {
    position: absolute;
    bottom: 30px;
    left: 16px;
    font-size: 18px;
    font-weight: bolder;
}

.add_licence_plate_btn {
    width: 100%;
    background-color: #f2555c !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    letter-spacing: normal !important;
    height: 50px !important;
}

.booking_header {
    position: absolute;
    bottom: 72px;
    top: 20px;
    left: 16px;
    font-size: 18px;
    font-weight: bolder;
}

.time_ago {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.0073529412em !important;
}

.time_ago_context {
    font-size: 14px;
    font-weight: 500;
}


.booking_divider {
    border-color: white !important;
    margin-top: 12px;
}

.booking_divider_div {
    position: absolute;
    bottom: 60px;
    width: 100%;
}

.booking_content {
    position: absolute;
    bottom: 18px;
    left: 16px;
    font-size: 13px;
    font-weight: normal;
}

.add_credit_card_btn {
    width: 100%;
    background-color: #f2555c !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    letter-spacing: normal !important;
    height: 50px !important;
}

.validation-pending {
    position: absolute !important;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.qr_text {
    font-size: 13px;
}

.exit_dialog_btn {
    width: 100%;
    background-color: #f2555c !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    letter-spacing: normal !important;
    height: 50px !important;
}

.sms-input input {
    font-size: 36px;
    font-weight: 500;
    color: #44515a !important;
}

.v-text-field--enclosed .v-input__append-inner {
    margin-top: 26px !important;
}

.exit-option-text {
    font-size: 14px;
}

.sms-caption {
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
}

.qrcode-stream-wrapper video {
    max-height: 300px;
}

.counter-content-position {
    position: relative;
}

.grace-period-image {
    position: absolute !important;
    right: -51px;
    bottom: 5px;
}

.fabIcon {
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
}

#qr-code-full-region__dashboard {
    display: none;
}

#qr-code-full-region>video {
    width: 100% !important;
}

#validation-qr-code>video {
    width: 100% !important;
}

#qr-code-full-region img {
    display: none;
}

.no-upper-case {
    text-transform: unset !important;
}

.time-picker-input {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0px;
    font-size: 16px;
    font-family: "Work Sans";
}

.time-picker-input :focus-visible {
    outline-color: #1976d2;
}

.vue__time-picker input.display-time {
    padding: 0px !important;
    border-bottom: 1px solid #767676;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
}

.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background: #f2555c !important;
}

.thankyou_header {
    position: absolute;
    bottom: 20px;
    left: 16px;
    font-size: 24px;
    font-weight: bolder;
}

[data-key='am'] {
    border: 0.2px solid red;
    border-radius: 2px;
    margin-left: 2px;
}

[data-key='pm'] {
    border: 0.2px solid red;
    margin-top: 0.5em;
    margin-left: 2px;
}

.hours .hint {
    position: sticky;
    top: 0;
    text-align: center;
    background: #68808c;
    z-index: 1;
    -webkit-text-fill-color: white;
    margin-bottom: 10px
}

.apms .hint {
    position: sticky;
    top: 0;
    text-align: center;
    background: #68808c;
    z-index: 1;
    margin-left: 1px;
    -webkit-text-fill-color: white;
    margin-bottom: 10px
}

.hours::-webkit-scrollbar {
    display: none;
}

.minutes::-webkit-scrollbar {
    display: none;
}

.minutes .hint {
    position: sticky;
    top: 0;
    text-align: center;
    background: #68808c;
    z-index: 1;
    -webkit-text-fill-color: white;
    margin-bottom: 10px
}

.booking_date {
    font-weight: normal;
    font-size: 13px;
    color: #3d3f3c !important;
}

.booking_date_header {
    color: #3d3f3c !important;
    font-weight: bold;
    font-size: 14px;
}

.total-parking {
    color: #3d3f3c;
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0.0892857143em !important;
    text-transform: uppercase !important;
}

.total {
    border: 1px solid #ffbc1f;
}

.amount-text {
    color: #3d3f3c;
    font-size: 34px;
}

.amount-content-position {
    position: relative;
}

.p-calendar {
    width: 100%;
}

#style {
    color: black !important;
}

.alert-msg-box {
    background-color: #ececec;
    padding: 15px 20px 10px;
    margin: 0px;
    width: fit-content;
    height: min-content;
}

.preauth_header {
    position: relative;
    margin-top: 8px;
    font-size: 18px;
    font-weight: bolder;
    z-index: 10 !important;
}
</style>
